import React from 'react';
import {connect} from 'react-redux';
import {Button} from '@br/br-components';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as TopSelectors from '../../../selectors/topLevelSelectors';
import * as AnalyticsActions from '../../../actions/analyticsActions';
import {screenNames, mParticleEventKeysMap} from '../../../constants/mParticle';

const CLASSES = 'cell newsletterSubsModule';

function determineModuleType(dismissible = false, isInFooter = false) {
  // flyIn, footer, midStream
  if (dismissible) {
    return 'flyIn';
  }
  if (isInFooter) {
    return 'footer';
  }
  return 'midStream';
}

const Form = ({
  className,
  dismissible,
  trackEvent,
  onDismiss,
  pageType,
  streamID,
  streamName,
  articleID,
  isInFooter,
}) => {
  function trackNewsletterSubscription() {
    trackEvent({
      screen: screenNames[pageType],
      streamName,
      streamID,
      articleID,
      module: determineModuleType(dismissible, isInFooter),
      tag_manager_event: mParticleEventKeysMap.newsletter_sign_up,
    });
  }

  const classes = cn(CLASSES, className);
  return (
    <div className={classes}>
      <div className="container">
        <h5 className="headline">🚨 SPORTS NEWS ➡️ YOUR INBOX</h5>
        <br />
        <p className="subheading">The latest in the sports world, emailed daily.</p>
        <br />
        <div className="input-and-submit-btn">
          <a
            href="https://bleacherreport.wyng.com/manage-inbox?page=page_3857924938674"
            target="blank"
            onClick={trackNewsletterSubscription}
          >
            <Button type="link">Join Newsletter</Button>
          </a>
        </div>
        {dismissible && (
          <Button onClick={onDismiss} classes="dismiss">
            Maybe later
          </Button>
        )}
      </div>
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  dismissible: PropTypes.bool,
  isInFooter: PropTypes.bool,
  onDismiss: PropTypes.func,
};

function mapStateToProps(state) {
  const ui = TopSelectors.ui(state);
  const page = TopSelectors.page(state);

  return {
    pageType: ui.pageType,
    streamName: page.id,
    streamID: page.tag_id,
    articleID: page.article,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
