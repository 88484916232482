import React from 'react';
import classnames from 'classnames';

const NumberedList = ({id, list}) => {
  const classes = classnames({
    atom: true,
    numberedList: true,
  });
  return (
    <ol className={classes}>
      {list.map((listItem, index) => (
        <li key={`listItem-${id}-${index}`} dangerouslySetInnerHTML={{__html: listItem}} />
      ))}
    </ol>
  );
};

export default NumberedList;
