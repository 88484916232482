export const determinDateBaseOnTimezone = (dateTime) => {
  let userDateTime;
  try {
    const convertedDateTime = new Date(dateTime).toLocaleString('en-US');
    userDateTime = {
      time: `${convertedDateTime.split(' ')[1].slice(0, -3)}${convertedDateTime.split(' ')[2]}`,
      date: convertedDateTime.split(',')[0],
    };
  } catch (_error) {
    userDateTime = dateTime;
  }
  return userDateTime;
};
