import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {useInView} from 'react-intersection-observer';

import * as TopSelectors from '../../selectors/topLevelSelectors';
import * as AnalyticsActions from '../../actions/analyticsActions';
import {screenNames} from '../../constants/mParticle';
import BettingDisclaimer from '../atoms/bettingDisclaimer';

const BettingLink = ({
  betType,
  ctaText,
  disclaimerText,
  disclaimerUrl,
  logo,
  logoClickUrl,
  partner,
  placement,
  trackEvent,
  ui,
}) => {
  // This ref is placed on the image for either partner_odds or betting_link,
  // So it will not trigger for the previous iteration of this component
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const sharedTrackingAttributes = {
    ctaText,
    logoFile: logo,
    partner,
    partnerUrl: logoClickUrl,
    placementName: `Gamecast ${placement} Module`,
    screen: screenNames[ui.pageType],
    streamName: ui.url,
  };

  useEffect(() => {
    if (inView) {
      trackEvent({
        ...sharedTrackingAttributes,
        tag_manager_event: 'betting_link_impression',
      });
    }
  }, [sharedTrackingAttributes, inView, trackEvent]);

  function trackLogoClick() {
    trackEvent({
      ...sharedTrackingAttributes,
      betType,
      tag_manager_event: 'betting_link_selected',
    });
  }

  const renderLogo = () => {
    if (!logo) {
      return null;
    }
    if (logoClickUrl) {
      return (
        <a href={logoClickUrl} onClick={trackLogoClick} target="_blank" rel="noreferrer">
          <img className="bettingLogo" src={logo} alt="betting logo" ref={ref} />
        </a>
      );
    }

    return <img className="sportsbookLogo" src={logo} alt="sportsbook logo" />;
  };

  const ctaClass = classnames({
    ctaText: true,
    ctaLarger: logoClickUrl,
  });

  return (
    <div className="molecule betting-link">
      <div className="link">
        <span className={ctaClass}>{ctaText}</span>
        {renderLogo()}
      </div>
      {!logoClickUrl && (
        <BettingDisclaimer disclaimerText={disclaimerText} disclaimerUrl={disclaimerUrl} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ui: TopSelectors.ui(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BettingLink);
