import classnames from 'classnames';

import {determineRel} from '../helpers/homePageHelpers';

const ArticleDescription = (props) => {
  if (!props.description) {
    return false;
  }

  const rel = determineRel(props.target);

  const classes = classnames({
    atom: true,
    articleDescription: true,
  });

  if (props.url) {
    return (
      <a className={classes} href={props.url} target={props.target} rel={rel}>
        <p>{props.description}</p>
      </a>
    );
  }

  return <p className={classes}>{props.description}</p>;
};

ArticleDescription.defaultProps = {
  description: '',
};

export default ArticleDescription;
