import React, {useState, useEffect} from 'react';
import {useInView} from 'react-intersection-observer';

import HouseAPI from '../../apis/house_api';
import FaustAPI from '../../apis/faust_api';

import BetCarousel from '../molecules/betCarousel';
import BettingLink from '../molecules/bettingLink';
import BetTrack from '../molecules/betTrack';

const betTracks = (betData, trackEvent, iframe) => {
  return betData.offers.map(
    ({
      analytics,
      display_name,
      display_name_short,
      bet_track_id,
      betting_link_legal: {cta_text, click_url, disclaimer_url},
      head_to_head,
      start_date,
      type,
    }) =>
      type !== 'three_way' && (
        <React.Fragment key={bet_track_id}>
          <BetTrack
            analytics={analytics}
            betName={display_name}
            headerText={display_name_short}
            leftOutcome={head_to_head?.left_outcome}
            rightOutcome={head_to_head?.right_outcome}
            startDate={start_date}
            url={click_url}
            showSubText={false}
            trackEvent={trackEvent}
            partnerUrl={disclaimer_url}
            ctaText={cta_text}
            iframe={iframe}
          />
        </React.Fragment>
      )
  );
};

const BetModule = ({betTags, trackEvent, iframe, league, hideBetTags}) => {
  const [betData, setBetData] = useState({});
  const [placeholderDisplayed, setPlaceholder] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  useEffect(() => {
    if (betTags?.length) {
      FaustAPI.getTagInfo(betTags, true)
        .then((response) => {
          const tags = response.map((tag) => tag.unique_name);
          const timezoneOffset = new Date().getTimezoneOffset() * 60;
          const tagString = tags.join();
          if (tagString?.length) {
            HouseAPI.fetchBets(tagString, -timezoneOffset)
              .then((response) => {
                setBetData(response);
                if (!response?.offer?.length) {
                  setPlaceholder(true);
                }
              })
              .catch(() => {
                setPlaceholder(true);
              });
          } else {
            setPlaceholder(true);
          }
        })
        .catch(() => {
          setPlaceholder(true);
        });
    } else {
      setPlaceholder(true);
    }
  }, [betTags]);

  useEffect(() => {
    if (!betData?.offers?.length && placeholderDisplayed && iframe && inView && trackEvent) {
      trackEvent({
        league,
        tag_manager_event: 'placeholder_impression',
      });
    }
  }, [betData, trackEvent, league, iframe, placeholderDisplayed, inView]);

  return (
    <div className="organism bet-module">
      {!hideBetTags && betData?.offers?.length ? (
        <div className="bet-content">
          <div className="bet-header">
            <div className="bet-title">{betData?.module_title}</div>
            <div className="bet-description">{betData?.module_description}</div>
          </div>
          <>
            <BetCarousel>{betTracks(betData, trackEvent, iframe)}</BetCarousel>
            <BettingLink
              ctaText={betData.offers[0].betting_link_non_legal.cta_text}
              logo={betData.offers[0].betting_link_non_legal.logo}
              disclaimerText={betData.offers[0].betting_link_legal.disclaimer_text}
              disclaimerUrl={betData.offers[0].betting_link_legal.disclaimer_url}
            />
          </>
        </div>
      ) : (
        <>
          {placeholderDisplayed && iframe ? (
            <img
              ref={ref}
              className="bet-placeholder"
              alt="bet placeholder"
              src="/img/bettingPlaceholder.png"
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default BetModule;
