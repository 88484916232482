import React from 'react';
import classnames from 'classnames';
import {AuthorInfo} from '@br/br-components';

import Commentary from '../atoms/commentary';
import TagLink from '../atoms/tagLink';
import ArticleDescription from '../atoms/articleDescription';
import ArticleTitle from '../atoms/articleTitle';
import VideoManager from '../molecules/video/videoManager';
import Timestamp from '../atoms/timestamp';
import {determineTarget} from '../helpers/homePageHelpers';
import {isVideo as isVideoContent} from '../../reducers/helpers/video_helpers';
import {contentTypes} from '../../constants/contentTypes';
import Thumbnail from '../molecules/thumbnail';

class ArticleSummary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onArticleClick = this.onArticleClick.bind(this);
    this.onStreamSelect = this.onStreamSelect.bind(this);
    this.determinePageType = this.determinePageType.bind(this);
  }
  onArticleClick() {
    this.props.onArticleClick(this.props.article.id);

    // Track mParticle Content Selected event for section pages
    this.props.trackEvent({
      article_id: this.props.metadata.article_id,
      content_id: this.props.article.id,
      content_type: this.props.article.content_type,
      screen: this.determinePageType(this.props.page),
      source: this.props.metadata.provider_name,
      stream_id: this.props.page.tag_id,
      stream_name: this.props.page.id,
      tag_manager_event: 'content_selected',
      title: this.props.article.content.title,
      url_hash: this.props.article.url_hash,
    });
  }
  onStreamSelect(...args) {
    return this.props.onStreamSelect(...args);
  }
  determinePageType() {
    if (this.props.page.format === 'editorial') {
      return 'Editorial';
    } else if (this.props.page.type === 'article') {
      return 'Article';
    }
    return 'Stream';
  }
  render() {
    const {
      streamType,
      article: {contentType},
      metadata: {provider_name},
    } = this.props;

    const isVideo = isVideoContent(contentType) || contentType === contentTypes.YOUTUBE_VIDEO;
    const isYoutube =
      contentType === contentTypes.YOUTUBE_VIDEO ||
      (provider_name && provider_name.toLowerCase().includes('youtube'));
    const url = this.props.article.externalUrl || this.props.article.url;
    const target = determineTarget(url);

    const classes = classnames({
      cell: true,
      articleSummary: true,
      video: isVideo,
      featuredImage: this.props.featured || isVideo,
    });
    const stream = this.props.showTeam ? this.props.article.tag : false;
    const author = {name: this.props.metadata.author_name, href: this.props.article.permalink};
    const commentary = this.props.commentary || {};
    const description = commentary.description || this.props.article.content.description;
    const timestamp = this.props.hasTimestamp ? this.props.timestamp : null;
    const title = this.props.article.content.title || this.props.metadata.title;

    const thumbData = {
      url,
      target,
      thumbnail: this.props.article.content.thumbnail_url || this.props.metadata.thumbnail_url,
      credit: this.props.photo_credit,
      featured: this.props.featured,
      imgsize: this.props.imgsize,
      streamType,
      title,
    };

    return (
      <this.props.tag
        className={classes}
        data-article-id={this.props.article.id}
        onClick={this.onArticleClick}
      >
        <Timestamp datetime={timestamp} />
        <Commentary {...commentary} />
        <div className="articleMedia">
          {isVideo ? (
            <VideoManager
              addStyle={this.props.addStyle}
              article={this.props.article}
              autoplay={this.props.autoplay}
              isYoutube={isYoutube}
              key={`video-${this.props.article.id}`}
              loadScript={this.props.loadScript}
              metadata={this.props.metadata}
              page={this.props.page}
              playVideoInline={this.props.playVideoInline}
              scrollPlay={this.props.scrollPlay}
              showThumb={true}
              showVideoPlaylist={false}
              site={this.props.site}
              tags={this.props.tags}
              title={this.props.article.content.title}
              thumbData={thumbData}
              trackEvent={this.props.trackEvent}
              updateVideosInViewport={this.props.updateVideosInViewport}
              visitorCountry={this.props.visitorCountry}
              cloudinaryWidth={970}
            />
          ) : (
            <Thumbnail articleID={this.props.article.id} cloudinaryWidth={650} {...thumbData} />
          )}
        </div>
        <div className="articleContent">
          <TagLink info={stream} onClick={this.props.onStreamSelect} tag="a" />
          <ArticleTitle lowerMargin={!description} target={target} title={title} url={url} />
          {streamType !== 'relatedContent' && <ArticleDescription description={description} />}
          <AuthorInfo
            hide={this.props.hide && this.props.hide.includes('author')}
            info={author}
            target={target}
            link={!this.props.isFeaturedArticle}
          />
        </div>
        <span className="provider">{provider_name ? `via ${provider_name}` : null}</span>
        {this.props.children}
      </this.props.tag>
    );
  }
}

ArticleSummary.defaultProps = {
  article: {
    content_type: '',
    content: {
      title: '',
      thumbnail_url: '',
    },
    externalUrl: '',
    url: '',
  },
  featured: false,
  hide: [],
  metadata: {
    provider_name: '',
    provider_url: '',
    thumbnail_url: '',
    title: '',
    video_url: '',
    video_id: null,
  },
  onArticleClick: () => {},
  page: {},
  showArrow: false,
  showTeam: true,
  tag: 'div',
  visitorCountry: 'US',
};

export default ArticleSummary;
