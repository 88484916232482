import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';

import * as AnalyticsActions from '../../actions/analyticsActions';
import {getScreenType} from '../../constants/mParticle';
import ImagesAPI from '../../apis/images_api';

import BrLogo from '../atoms/brLogo';

// exported funcs

export const getLogoFileName = function(props) {
  return props.info.logo_filename || props.info.logo;
};

export const getTagLink = function(props) {
  const link = props.info.link || `/${props.info.unique_name}`;
  return `${link}#tag`; //Added #tag to track springType in sectionpages
};

class TagLink extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      logoError: false,
    };
  }

  componentDidMount() {
    // use DOM properties of an image to check if the image has:
    // a) Loaded (img.complete)
    // b) Has an *actual* height for the image file (img.naturalHeight)
    // c) Has an *actual* width for the image file (img.naturalWidth)
    // the last two sanity check each other.
    const logoExists = !!this._logo;
    const logoLoaded = logoExists && this._logo.complete;
    const logoHasNaturalSize = logoExists && this._logo.naturalHeight && this._logo.naturalWidth;
    if (logoExists && logoLoaded && !logoHasNaturalSize) {
      // this is the only (and best) place to do the following, so disabling this check.
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({logoError: true});
    }
  }

  getName() {
    //Todo : This is a temporary fix for RWB-909 and will be reverted in RWB-910
    if (this.props.info.tag_id === 135296) {
      //Changing the name to 'Capital One's The Match' if it is 'tiger-vs-phil'
      return "Capital One's The Match";
    }
    if (this.props.moreStreams) {
      return '\u003e'; // encoded greater-than sign.
    } else if (this.props.useShortName && this.props.info.short_name) {
      return this.props.info.short_name;
    }
    return this.props.info.name || this.props.info.display_name;
  }

  onClick() {
    this.props.trackEvent({
      tag_manager_event: 'content_selected',
      urlHash: this.props.info.href,
      screen: getScreenType(this.props.ui?.pageType, this.props.ui?.pageSubType),
      contentType: this.props.contentType || 'stream',
      springType: 'hyperlink',
      title: this.getName(),
    });
    return this.props.onClick(this.props.info.permalink);
  }

  onError() {
    this.setState({logoError: true});
  }

  render() {
    if (!this.props.info) {
      return false;
    }
    const classes = classnames({
      atom: true,
      tagLink: true,
      home: this.props.homeOrAway === 'home',
      away: this.props.homeOrAway === 'away',
      moreStreams: this.props.moreStreams,
      selected: this.props.selected,
    });
    const name = this.getName();
    const logoLocation = getLogoFileName(this.props);
    const href = getTagLink(this.props);
    const logo =
      this.state.logoError || !logoLocation ? (
        <BrLogo />
      ) : (
        <img
          src={ImagesAPI.teamLogo(logoLocation, 'large')}
          alt={`${name} logo`}
          onError={this.onError}
          ref={(elem) => (this._logo = elem)}
        />
      );
    const styles = {
      backgroundColor: this.props.teamColor ? `#${this.props.teamColor}` : null,
    };

    return (
      <this.props.tag
        className={classes}
        onClick={this.onClick}
        href={this.props.tag === 'a' ? href : null}
      >
        <div className="imgWrapper" style={styles}>
          {logo}
        </div>
        <span className="teamAvatar__name">{name}</span>
      </this.props.tag>
    );
  }
}

TagLink.defaultProps = {
  moreTeams: false,
  tag: 'div',
  info: {},
  selected: false,
  teamColor: false,
  useShortName: false,
  onClick() {}, // stub
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TagLink);
