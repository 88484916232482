import React from 'react';
import {DateTime} from 'luxon';
import {format} from 'date-fns';

import BettingLink from '../molecules/bettingLink';
import BetTrack from '../molecules/betTrack';

const BetTrackPost = ({
  creationDate,
  offer: {
    display_name_short,
    type,
    bet_category,
    bet_locked_string,
    head_to_head: {left_outcome, right_outcome},
    betting_link_legal: {click_url, disclaimer_text, disclaimer_url},
    betting_link_non_legal: {cta_text, logo},
    start_date,
  },
  timezone,
}) => {
  const formatCreationDate = (dateInZuluTime, timezone) => {
    return format(DateTime.fromISO(dateInZuluTime, {zone: timezone}), 'MMMM D, YYYY');
  };

  return (
    <div className="atom bet-track-post">
      <header>
        <div className="divider" />
        <label className="date">{formatCreationDate(creationDate, timezone)}</label>
      </header>
      {bet_category === 'Standard' && (
        <>
          {type === 'head_to_head' && (
            <BetTrack
              headerText={display_name_short}
              leftOutcome={left_outcome}
              rightOutcome={right_outcome}
              url={click_url}
              startDate={start_date}
              lockedText={bet_locked_string}
              showSubText={true}
              timezone={timezone}
            />
          )}
        </>
      )}
      <BettingLink
        ctaText={cta_text}
        logo={logo}
        disclaimerText={disclaimer_text}
        disclaimerUrl={disclaimer_url}
      />
    </div>
  );
};

export default BetTrackPost;
