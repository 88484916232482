const BettingDisclaimer = ({disclaimerText, disclaimerUrl}) => {
  return (
    <div className="atom betting-disclaimer">
      <a
        className="disclaimer"
        href={disclaimerUrl}
        dangerouslySetInnerHTML={{__html: disclaimerText}}
        target="_blank"
        rel="noopener noreferrer"
      />
    </div>
  );
};

export default BettingDisclaimer;
