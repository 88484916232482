import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import {useInView} from 'react-intersection-observer';

import Commentary from '../atoms/commentary';
import Timestamp from '../atoms/timestamp';

const twitterLoad = (elem) => {
  if (elem && global.twttr && global.twttr.widgets) {
    global.twttr.widgets.load(elem);
  }
};

const TwitterEmbed = (props) => {
  const {lang, hasTimestamp, timestamp, commentary, name, account, content} = props;
  // BR-1324 - Twitter's embed API does not work with x.com URLs currently.
  // TODO: Remove when x.com is supported
  const url = props.url.replace('x.com', 'twitter.com');
  const tweetBlockquouteRef = useRef();
  const [wrapperRef, inView] = useInView({
    rootMargin: '300px 0px',
  });

  useEffect(() => {
    if (inView) {
      //Only add the "twitter-tweet" class to the blockquoute when we're ready to load it, otherwise the twitter script will load it once page is ready.
      tweetBlockquouteRef.current.classList.add('twitter-tweet');
      twitterLoad(tweetBlockquouteRef.current);
    }
  }, [inView, tweetBlockquouteRef, url]);

  const classes = classnames({
    atom: true,
    twitterEmbed: true,
  });

  return (
    <props.tag ref={wrapperRef} className={classes}>
      <Timestamp datetime={hasTimestamp ? timestamp : null} />
      <Commentary {...commentary} />
      <div className="twitterEmbed__container">
        <blockquote
          ref={tweetBlockquouteRef}
          className="tweet-blockquote tw-align-center"
          data-lang={lang}
        >
          {name} <span className="acct">@{account}</span>
          <p lang={lang} dir="ltr">
            {content}
          </p>
          <a href={url} />
        </blockquote>
      </div>
    </props.tag>
  );
};

/* defaults */
TwitterEmbed.defaultProps = {
  tag: 'div',
  lang: 'en',
  addScript: () => {}, // stub
};

export default TwitterEmbed;
