import {Component} from 'react';
import classnames from 'classnames';
import Commentary from '../atoms/commentary';

class FacebookEmbed extends Component {
  constructor(props) {
    super(props);
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const isHttps = this.props.url.startsWith('https://');
    const classes = classnames({
      atom: true,
      error: !isHttps,
      facebookEmbed: true,
    });
    const embedType = this.props.type === 'facebook_video_post' ? 'video' : 'post';

    return isHttps ? (
      <this.props.tag className={classes}>
        {this.props.commentary ? <Commentary {...this.props.commentary} /> : false}
        <div className={`fb-${embedType}`} data-width="500" data-href={this.props.url} />
      </this.props.tag>
    ) : (
      <div className={classes}>
        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
          Link to Media
        </a>
      </div>
    );
  }
}

/* defaults */
FacebookEmbed.defaultProps = {
  addScript: () => {}, // stub
  commentary: {},
  tag: 'div',
  url: '',
};

export default FacebookEmbed;
