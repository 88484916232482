import React from 'react';
import classnames from 'classnames';
import {format} from 'date-fns';
import {SVG, Table} from '@br/br-components';
import debounce from 'lodash.debounce';
import {orderBy} from 'lodash';
import {InView} from 'react-intersection-observer';

import Advert from '../atoms/advert';
import ArticleSummary from '../cells/articleSummary';
import BetModule from './betModule';
import BetTrackPost from '../atoms/betTrackPost';
import BulletedList from '../atoms/bulletedList';
import FacebookEmbed from '../atoms/facebookEmbed';
import Iframe from '../atoms/iframe';
import InstagramEmbed from '../atoms/instagramEmbed';
import LazyAdvert from '../atoms/lazyAdvert';
import NumberedList from '../atoms/numberedList';
import Photo from '../atoms/photo';
import Poll from '../atoms/poll';
import ExternalLinkIcon from '../atoms/ExternalLinkIcon';
import TagLink from '../atoms/tagLink';
import TextPost from '../atoms/textPost';
import TwitterEmbed from '../atoms/twitterEmbed';
import VideoManager from '../molecules/video/videoManager';
import YoutubeEmbed from '../molecules/video/youtubeEmbed';
import NewsletterSubsModule from '../cells/newsletterSubscription/inlineModule';

import {bodyScrollTop, isPartiallyInViewport, percentInWindow} from '../helpers/domHelpers';
import {determinDateBaseOnTimezone} from '../../helpers/timezoneHelpers';
import {contentTypes} from '../../constants/contentTypes';
import {getScreenType} from '../../constants/mParticle';
import {ARTICLE, STUB} from '../../constants/pageTypes';

// NR-520 introduces a limit to the total number of ads we want rendered in any given article page (iOS only)
// The issue seems to only affect very long slideshows but could potentially occur in any long article page
// Ads are not the cause of the issue but rather something we can easily alleviate the problem on mobile Safari
const iosAdsLimit = process.env.IOS_ADS_LIMIT ? parseInt(process.env.IOS_ADS_LIMIT, 10) : 20;

const generateUniqID = ({id, playlist_id, url_hash, slide_number}, page) => {
  if (page && page.type === ARTICLE) {
    const slide = slide_number ? slide_number : 0;
    return [page.article, id, slide].join('-');
  }
  if (id && playlist_id && url_hash) {
    return [playlist_id, id, url_hash].join('-');
  }
  return id;
};

function isMajorityInScreen(elem) {
  if (!elem) {
    return false;
  }

  if (isPartiallyInViewport(elem)) {
    return percentInWindow(elem) > 50;
  }

  return false;
}

const shouldAutoplayVideo = ({index, isLive, props}) => {
  const isFirstTrack = index === 0;
  return (
    (isFirstTrack && !props.slide && !isLive && props.page && props.page.format !== 'editorial') ||
    (props.isFeaturedArticle && isFirstTrack) ||
    // Videos should autoplay on stubs even when not the first track
    // Programmers often add a paragraph above the video for live events
    props.pageType === STUB
  );
};

const getLazyAdvert = (info) => {
  const slotPosition = `rect_atf_0${Math.min(info.adCount, 3)}`;
  return (
    <LazyAdvert
      key={`ad-${generateUniqID(info.streamItem, info.props.page)}`}
      id={info.streamItem.id}
      country={info.props.visitorCountry}
      isVisitorOnMobile={info.props.isMobileDevice}
      position={slotPosition}
      page={info.props.page}
      tag={info.props.childTag}
      responsiveSize={info.responsiveSize}
    />
  );
};
const getInstagramEmbed = (info) => {
  const metadata = info.streamItem.content.metadata;
  return (
    <InstagramEmbed
      key={`instagram-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      addScript={info.props.addScript}
      url={info.streamItem.url}
      author_name={metadata.author_name}
      username={metadata.username}
      commentary={info.commentary}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
    />
  );
};
const getAdvert = (info) => {
  return (
    <Advert
      id="1"
      country={info.props.user.country}
      isVisitorOnMobile={info.props.ui.isMobileDevice}
      position="bnr_atf_01"
      responsiveSize="wide"
      size={[
        [320, 50],
        [320, 1],
      ]}
      reload={2}
      autoHeight={true}
      key="br-ad-1"
    />
  );
};

const getBetModule = (info) => {
  return (
    <BetModule
      key={`bet-${generateUniqID(info.streamItem, info.props.page)}`}
      betTags={info.props.betTags}
      trackEvent={info.props.trackEvent}
      hideBetTags={info.props.hideBetTags}
    />
  );
};

const getTicketsPromo = (info) => {
  const game = info.props.upcomingGame?.game;

  const handleClick = () => {
    info.props.trackEvent({
      tag_manager_event: 'ticket_link_selected',
      screen: getScreenType(info.props.page?.type, info.props.page?.pageSubType),
      streamName: info.props.page?.id,
      referrer_source: info.props.page?.utm_source || global.document?.referrer,
      streamID: info.props.page?.tag_id,
      module: 'midStream',
    });
    return true;
  };

  return game && info.props.isTeamSection ? (
    <div key="ticketsPromo" className="ticketsLink">
      <div className="ticketsLinkInner">
        <p>
          {info.props.info?.name || info.props.info?.display_name}
          &nbsp;
          {game?.home_away}
          &nbsp;
          {game?.opponent}
          &nbsp;
          {format(determinDateBaseOnTimezone(game?.iso_utc_datetime)?.date, 'MM/DD')}
          <br />
          tickets as low as {game?.ticket_info?.min_price}
        </p>
        <a
          className="atom button"
          href={game?.ticket_info?.url}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          Buy Tickets
          <ExternalLinkIcon />
        </a>
      </div>
    </div>
  ) : (
    false
  );
};
const getTweet = (info) => {
  const metadata = info.streamItem.content.metadata;
  return metadata.twitter_json ? (
    <TwitterEmbed
      key={`twitter-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      addScript={info.props.addScript}
      content={metadata.twitter_json.text}
      commentary={info.commentary}
      url={info.streamItem.url}
      lang={metadata.twitter_json.lang}
      name={metadata.twitter_json.user ? metadata.twitter_json.user.name : ''}
      account={metadata.twitter_json.user ? metadata.twitter_json.user.screen_name : ''}
      date={metadata.twitter_json.created_at}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
    />
  ) : (
    false
  );
  // if we have no Twitter metadata (bad embed) we can't render the tweet.
};
const getArticleSummary = (info) => {
  return (
    <ArticleSummary
      addStyle={info.props.addStyle}
      key={`article-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      autoplay={info.autoplay}
      article={info.streamItem}
      isTrendingArticle={info.props.isTrendingArticle} // displayed in trending spot on sidebar
      page={info.props.page}
      metadata={info.streamItem.content.metadata}
      trackEvent={info.props.trackEvent}
      commentary={info.commentary}
      featured={info.props.featured(info.props, info.streamItem)}
      showarrow={info.props.showarrow}
      noimages={info.props.noimages}
      imgsize={info.props.imgsize}
      playVideoInline={info.props.playVideoInline}
      scrollPlay={info.props.scrollPlay}
      loadScript={info.props.loadScript}
      showTeam={info.props.showTeams}
      onStreamSelect={info.onStreamSelect}
      onArticleClick={info.onArticleClick}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
      hide={info.streamItem.hidden_attributes}
      updateVideosInViewport={info.props.updateVideosInViewport}
      isFeaturedArticle={info.props.isFeaturedArticle}
      streamType={info.props.streamType}
    />
  );
};
const getYoutubeEmbed = (info) => {
  if (
    !info.commentary.title &&
    info.streamItem.content.metadata &&
    info.streamItem.content.metadata.title
  ) {
    // If BR Editors haven't added commentary, show the video's own title.
    // This happens on stream pages, not within article content. Within
    // article content, the streamItem here won't have a normal-shaped
    // streamItem.content.metadata, so the above conditional won't be true.
    // TODO... this should be refactored as part of NR-186.
    info.commentary.title = info.streamItem.content.metadata.title;
  }

  return (
    <YoutubeEmbed
      commentary={info.commentary}
      hasTimestamp={info.isLive}
      id={info.streamItem.video_id || info.streamItem.content.metadata.video_id}
      key={`youtube-${generateUniqID(info.streamItem, info.props.page)}`}
      playsInline={true}
      oneTrustPreferences={info.props.oneTrustPreferences}
      tag={info.props.childTag}
      timestamp={info.streamItem.updated_at}
      startSeconds={info.streamItem.content?.metadata?.start_seconds}
    />
  );
};
const getText = (info) => {
  return (
    <TextPost
      key={`text-${generateUniqID(info.streamItem, info.props.page)}`}
      id={info.streamItem.id}
      tag={info.props.childTag}
      title={info.streamItem.content.title}
      content={info.streamItem.content.description}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
    />
  );
};
const getGIF = (info) => {
  return (
    <Photo
      key={`photo-${generateUniqID(info.streamItem, info.props.page)}`}
      type="gif"
      tag={info.props.childTag}
      src={info.streamItem.content.url || info.streamItem.url}
      commentary={info.commentary}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
      credit={info.streamItem.content.credit}
      alt={info.streamItem.content.source_caption}
    />
  );
};
const getPhoto = (info) => {
  return (
    <Photo
      key={`photo-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      src={info.streamItem.content.thumbnail_url}
      commentary={info.commentary}
      hasTimestamp={info.isLive}
      timestamp={info.streamItem.updated_at}
      caption={info.streamItem.content.caption}
      alt={info.streamItem.content.source_caption}
    />
  );
};
const getImage = (info) => {
  return (
    <Photo
      key={`photo-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      type={info.streamItem.content_type}
      src={info.streamItem.content.url || info.streamItem.url}
      credit={info.streamItem.content.credit}
      userCaption={info.streamItem.content.user_caption}
      caption={info.streamItem.content.caption}
      size={{w: 800, h: 533}}
      isLeadImage={info.streamItem.isLeadImage}
      blurArticleLeadImage={info.props.ui.blurArticleLeadImage}
      alt={info.streamItem.content.source_caption}
    />
  );
};
const getSlide = (info) => {
  const slideNumber = info.streamItem.slide_number;
  return (
    <ContentStream
      addScript={info.props.addScript}
      addStyle={info.props.addStyle}
      header={true}
      imgsize={'largeTS'}
      key={`slide-${generateUniqID(info.streamItem, info.props.page)}`}
      loadScript={info.props.loadScript}
      onArticleClick={info.onArticleClick}
      os={info.props.os}
      page={info.props.page}
      oneTrustPreferences={info.props.oneTrustPreferences}
      scrollPlay={info.props.scrollPlay}
      setSlide={info.props.setSlide}
      slide={true}
      slideCount={info.props.slideCount}
      slideNumber={slideNumber}
      streamItems={info.streamItem.elements}
      tag={info.props.childTag}
      title={info.streamItem.title}
      toggleSlideshowNav={info.props.toggleSlideshowNav}
      trackEvent={info.props.trackEvent}
      ui={info.props.ui}
      updateVideosInViewport={info.props.updateVideosInViewport}
      visitorCountry={info.props.visitorCountry}
    >
      <div className="slideData">{`${slideNumber} of ${info.props.slideCount - 1}`}</div>
    </ContentStream>
  );
};
const getFacebookEmbed = (info) => {
  return (
    <FacebookEmbed
      key={`fb-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      addScript={info.props.addScript}
      commentary={info.streamItem.content ? info.commentary : false}
      url={info.streamItem.url}
      type={info.streamItem.content_type}
    />
  );
};
const getStatMilk = (info) => {
  return (
    <Iframe
      key={`statmilk-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      src={info.streamItem.content.src}
      height={info.streamItem.content.height}
      provider="statmilk"
    />
  );
};
const getIframe = (info) => {
  return (
    <Iframe
      key={`iframe-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.props.childTag}
      src={info.streamItem.content.src}
      provider={info.streamItem.content.provider}
    />
  );
};
const getPoll = (info) => {
  const metadata = info.streamItem.content.metadata;
  const poll = info.props.pollData && info.props.pollData[metadata.poll_id];

  return (
    poll && (
      <Poll
        title={poll.display_text}
        description={poll.commentary_text}
        creationDate={poll.date_created}
        answers={poll.poll_answers}
        isPollClosed={poll.poll_closed}
      />
    )
  );
};
const getBetTrack = (info) => {
  const metadata = info.streamItem.content.metadata;
  const betTrackData = info.props.betTrackData && info.props.betTrackData[metadata.bet_track_id];
  return (
    betTrackData && (
      <BetTrackPost
        creationDate={betTrackData.date_created}
        offer={betTrackData.content.metadata.bet_offer}
        timezone={info.props.timezone}
      />
    )
  );
};
const getVideo = (info) => {
  const metadata = info.streamItem.content.metadata;
  const videoMetadata = info.props.videoMetadata && info.props.videoMetadata[metadata.video_id];

  const thumbData = {
    thumbnail:
      info.streamItem.content.thumbnail_url || (videoMetadata && videoMetadata.thumbnail_url),
  };

  return (
    <VideoManager
      addStyle={info.props.addStyle}
      article={info.streamItem}
      author={info.props.author}
      autoplay={info.autoplay}
      commentary={info.commentary}
      experimentType={info.props.experimentType}
      index={info.index}
      gizmoProduct={info.props.gizmoProduct}
      key={`video-${generateUniqID(info.streamItem, info.props.page)}`}
      liveVideoMetadata={info.props.liveVideoMetadata}
      loadScript={info.props.loadScript}
      metadata={metadata}
      muted={true}
      page={info.props.page}
      playNextVideo={info.props.playNextVideo}
      playSpecificVideo={info.playSpecificVideo}
      publishedDate={info.props.publishedDate}
      scrollPlay={info.props.scrollPlay}
      showVideoPlaylist={info.props.showVideoPlaylist}
      showThumb={true}
      thumbData={thumbData}
      site={info.props.site}
      tag={info.props.childTag}
      tags={info.props.tags}
      title={info.props.title || info.streamItem.content.title}
      trackEvent={info.props.trackEvent}
      ui={info.props.ui}
      unavailableInRegionImageUrl={info.props.unavailableInRegionImageUrl}
      updateVideosInViewport={info.props.updateVideosInViewport}
      url={info.props.url}
      user={info.props.user}
      videoMetadata={info.props.videoMetadata}
      videoPlaylist={info.props.videoPlaylist}
      videoPlaylistStatus={info.props.videoPlaylistStatus}
      videoRecommendations={info.props.videoRecommendations}
      cloudinaryWidth={970}
    />
  );
};
const getBulletedList = (info) => {
  return (
    <BulletedList
      key={`bulletedlist-${generateUniqID(info.streamItem, info.props.page)}`}
      tag={info.streamItem.content_type}
      list={info.streamItem.content.items}
      id={info.streamItem.id}
    />
  );
};
const getNumberedList = (info) => {
  return (
    <NumberedList
      key={`numberedlist-${generateUniqID(info.streamItem, info.props.page)}`}
      list={info.streamItem.content.items}
      id={info.streamItem.id}
    />
  );
};
const getParagraph = (info) => {
  return (
    <p
      className={classnames({'align-text-with-title': info.props.alignTextWithTitle})}
      key={`p-${generateUniqID(info.streamItem, info.props.page)}`}
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};
const getObject = (info) => {
  return (
    <info.props.childTag
      key={`obj-${generateUniqID(info.streamItem, info.props.page)}`}
      className="object"
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};
const getBlockquote = (info) => {
  return (
    <blockquote
      key={`quote_indent-${generateUniqID(info.streamItem, info.props.page)}`}
      className="quote_indent"
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};
const getOldPullquote = (info) => {
  return (
    <blockquote
      key={`blockquote-${generateUniqID(info.streamItem, info.props.page)}`}
      className="blockquote"
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};
const getPullquote = (info) => {
  return (
    <blockquote
      key={`quote_pull-${generateUniqID(info.streamItem, info.props.page)}`}
      className="quote_pull"
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};
const getHeadingsAndDiv = (info) => {
  // Article content shouldn't contain h1 nor h4/h5/h6 but it does;
  // convert h1 to h2, and the rest to h3.
  const contentType = info.streamItem.content_type.toLowerCase();
  const Tag = ['h1', 'h2'].includes(contentType) ? 'h2' : 'h3';
  return (
    <Tag
      key={`${contentType}-${generateUniqID(info.streamItem, info.props.page)}`}
      className="streamElement"
      dangerouslySetInnerHTML={{__html: info.streamItem.content.html}}
    />
  );
};

let hrCounter = 0;
const getHR = () => {
  return <hr key={`hr-${hrCounter++}`} />;
};
const getTransparentHR = () => {
  return <hr key={`hr-transparent-${hrCounter++}`} className="transparent" />;
};

const getTable = (info) => {
  return (
    <Table
      key={`table-${generateUniqID(info.streamItem, info.props.page)}`}
      data={info.streamItem.content}
    />
  );
};
const contentTypePairs = {
  [contentTypes.TOP_AD]: getAdvert,
  [contentTypes.AD]: getLazyAdvert,
  [contentTypes.BET]: getBetModule,
  [contentTypes.BET_TRACK]: getBetTrack,
  [contentTypes.TICKETS_PROMO]: getTicketsPromo,
  [contentTypes.TWEET]: getTweet,
  [contentTypes.YOUTUBE_VIDEO]: getYoutubeEmbed,
  [contentTypes.TEXT]: getText,
  [contentTypes.GIF]: getGIF,
  [contentTypes.PHOTO]: getPhoto,
  [contentTypes.IMAGE]: getImage, //used for articles
  [contentTypes.SLIDE]: getSlide,
  [contentTypes.STATMILK]: getStatMilk,
  [contentTypes.IFRAME]: getIframe,
  [contentTypes.PARAGRAPH]: getParagraph,
  [contentTypes.POLL]: getPoll,
  [contentTypes.OBJECT]: getObject,
  [contentTypes.QUOTE_INDENT]: getBlockquote,
  [contentTypes.QUOTE_OLD]: getOldPullquote,
  [contentTypes.QUOTE_PULL]: getPullquote,
  [contentTypes.HR]: getHR,
  [contentTypes.HR_TRANSPARENT]: getTransparentHR,
  [contentTypes.TABLE]: getTable,

  [contentTypes.INSTAGRAM]: getInstagramEmbed,
  [contentTypes.INSTAGRAM_IMAGE]: getInstagramEmbed,
  [contentTypes.INSTAGRAM_VIDEO]: getInstagramEmbed,

  [contentTypes.DEEPLINK]: getArticleSummary,
  [contentTypes.INTERNAL_ARTICLE]: getArticleSummary,
  [contentTypes.EXTERNAL_ARTICLE]: getArticleSummary,
  [contentTypes.VIDEO_ARTICLE]: getArticleSummary,
  [contentTypes.LAB_ARTICLE]: getArticleSummary,

  [contentTypes.FACEBOOK_POST]: getFacebookEmbed,
  [contentTypes.FACEBOOK_IMAGE_POST]: getFacebookEmbed,
  [contentTypes.FACEBOOK_VIDEO_POST]: getFacebookEmbed,

  [contentTypes.CREATORS]: getVideo,
  [contentTypes.HIGHLIGHT]: getVideo,
  [contentTypes.LIVE_VIDEO]: getVideo,
  [contentTypes.VIDEO]: getVideo,

  [contentTypes.UL]: getBulletedList,
  [contentTypes.OL]: getNumberedList,

  [contentTypes.H1]: getHeadingsAndDiv,
  [contentTypes.H2]: getHeadingsAndDiv,
  [contentTypes.H3]: getHeadingsAndDiv,
  [contentTypes.H4]: getHeadingsAndDiv,
  [contentTypes.H5]: getHeadingsAndDiv,
  [contentTypes.H6]: getHeadingsAndDiv,
  [contentTypes.DIV]: getHeadingsAndDiv,
};
export function determineComponent(
  adCount,
  streamItem,
  props,
  onStreamSelect,
  onArticleClick,
  onTrackImpression,
  playSpecificVideo,
  isLive,
  index,
  responsiveSize
) {
  const isRealCommentary =
    streamItem.content && streamItem.content.commentary && streamItem.content.commentary.title;
  // Not all streamItems have content (like in Articles)
  // if they do we need to make sure they have commentary (or force them to).
  const commentary = streamItem.content
    ? isRealCommentary
      ? streamItem.content.commentary
      : {title: streamItem.content.title}
    : {}; // TODO: NR-186
  const autoplay = shouldAutoplayVideo({
    index,
    isLive,
    props,
    streamItem,
  });

  const info = {
    adCount,
    autoplay,
    commentary,
    index,
    isLive,
    onArticleClick,
    onStreamSelect,
    playSpecificVideo,
    props,
    responsiveSize,
    streamItem,
  };

  function handleTrackImpression(inView) {
    // contentIds are integers. If it's a string, it's a placeholder
    const impressionId = Number.isInteger(streamItem.id) ? streamItem.id : null;
    if (inView && impressionId) {
      onTrackImpression(impressionId);
    }
  }

  const componentFunction = contentTypePairs[streamItem.content_type];
  return (
    componentFunction && (
      <InView
        triggerOnce={true}
        onChange={handleTrackImpression}
        className="intersectionObserverRefElement"
      >
        {componentFunction(info)}
      </InView>
    )
  );
}

// This date format is used for comparing datetimes, in order to determine
// whether to show a new "date boundary" header above an individual post within
// a liveblog.
const LIVEBLOG_POSTHEADER_DATEFORMAT = 'MMMM D, YYYY';

const buildContentStream = (props) => {
  if (!props.streamItems) {
    return false;
  }
  // create event functions outside of the loop.
  const onStreamSelect = (...args) => props.onStreamSelect(...args);
  const onArticleClick = (...args) => props.onArticleClick(...args);
  const playSpecificVideo = (...args) => props.playSpecificVideo(...args);
  const onTrackImpression = props.onTrackImpression;
  const isIos = props.os === 'ios';
  let adCount = 0;
  let contentStreamComponentList = [];

  if (props.liveData) {
    let dateOfLastPost = format(new Date(), LIVEBLOG_POSTHEADER_DATEFORMAT); // Use today's date as starting point for evaluating where to insert date boundaries.
    contentStreamComponentList = props.streamItems.reduce((componentList, streamItem, index) => {
      if (streamItem.content_type === 'ad') {
        if (isIos && adCount >= iosAdsLimit) {
          return componentList;
        }
        adCount += 1;
        componentList.push(
          determineComponent(
            adCount,
            streamItem,
            props,
            onStreamSelect,
            onArticleClick,
            onTrackImpression,
            playSpecificVideo,
            true,
            index
          )
        );
      } else if (
        // There is a lag in the service when adding $...metadata.creators
        // We don't want to render the streamItem until the data is available
        // This will filter items when CREATORS_ENABLED !== 'true',
        // since $...metadata.creators will not exist
        streamItem.content_type === contentTypes.CREATORS &&
        !streamItem.content.metadata.creators
      ) {
        return componentList;
      } else {
        const updatedAtDateReadable = format(streamItem.updated_at, LIVEBLOG_POSTHEADER_DATEFORMAT);
        if (updatedAtDateReadable !== dateOfLastPost) {
          componentList.push(
            <props.childTag
              key={`date-boundary-${generateUniqID({
                id: format(streamItem.updated_at, 'YYYYMMDDThhmmss'),
              })}`}
              className="date-boundary"
            >
              {updatedAtDateReadable}
            </props.childTag>
          );
          dateOfLastPost = updatedAtDateReadable;
        }
        componentList.push(
          determineComponent(
            adCount,
            streamItem,
            props,
            onStreamSelect,
            onArticleClick,
            onTrackImpression,
            playSpecificVideo,
            true,
            index
          )
        );
      }
      return componentList;
    }, []);
  } else {
    let streamItems = [...props.streamItems];
    if (props.pollData && props.videoMetadata) {
      //If it is a video poll , the elements should be ordered by video
      const orderedElements = orderBy(props.streamItems, (elm) => elm.content_type, ['desc']);
      streamItems = [...orderedElements];
    }
    contentStreamComponentList = streamItems.reduce((componentList, streamItem, index) => {
      if (index === 0 && streamItem.content_type === contentTypes.IMAGE) {
        streamItem.isLeadImage = true;
      }
      if (streamItem.content_type === 'ad') {
        if (isIos && adCount >= iosAdsLimit) {
          return componentList;
        }
        adCount += 1;
      }

      if (
        // There is a lag in the service when adding $...metadata.creators
        // We don't want to render the streamItem until the data is available
        // This will filter items when CREATORS_ENABLED !== 'true',
        // since $...metadata.creators will not exist
        streamItem.content_type === contentTypes.CREATORS &&
        !streamItem.content.metadata.creators
      ) {
        return componentList;
      }
      componentList.push(
        determineComponent(
          adCount,
          streamItem,
          props,
          onStreamSelect,
          onArticleClick,
          onTrackImpression,
          playSpecificVideo,
          false,
          index
        )
      );
      return componentList;
    }, []);
  }

  if (props.showNewsletterSubsModule) {
    contentStreamComponentList.splice(7, 0, <NewsletterSubsModule />);
  }

  return contentStreamComponentList;
};

const ContentStreamTitle = (props) => {
  // this is being used as a stateless component. Need to look at how to apply Atomic ideas here/to the whole file.
  if (!props.header) {
    return false;
  }
  if (props.title) {
    return <h2>{props.title}</h2>;
  }
  if (props.info) {
    return (
      <h1>
        <TagLink info={props.info} />
      </h1>
    );
  }
  return false;
};

class ContentStream extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = debounce(this._handleScroll.bind(this), 100);
    this.onTrackImpression = this.onTrackImpression.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.slideRef = this.slideRef.bind(this);
    this.state = {trackImpressions: []};
  }

  componentDidMount() {
    global.addEventListener('beforeunload', this.onUnload);
    if (this.props.liveData) {
      this.props.updateLivestream(this.props.page.id);
      global.document.addEventListener('scroll', this.handleScroll);
    }

    if (this.props.slide) {
      global.document.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.liveData) {
      this.props.stopUpdatingLivestream();
    }
  }

  _handleScroll() {
    if (this.props.liveData) {
      if (this.isScrolledToTop()) {
        this.props.updateLivestream(this.props.page.id);
      } else {
        this.props.stopUpdatingLivestream();
      }
    }

    if (this.props.slide && isMajorityInScreen(this._slide)) {
      this.props.setSlide(this.props.slideNumber, this._slide);
    }
  }

  onTrackImpression(trackImpression) {
    this.setState({
      trackImpressions: [...this.state.trackImpressions, trackImpression],
    });
  }

  onUnload() {
    // There are a few instances where there are multiple ContentStreams on a page
    // Some of these items do not render any items depending on the data
    // We only want to track the main stream impressions that are actually viewed.
    if (!this.state.trackImpressions.length) {
      return;
    }
    this.props.trackEvent({
      tag_manager_event: 'stream_summary',
      streamName: this.props.page.id,
      trackImpressions: this.state.trackImpressions.join(','),
      totalTracksImpression: this.state.trackImpressions.length,
    });
    global.removeEventListener('beforeunload', this.onUnload);
  }

  isScrolledToTop() {
    return bodyScrollTop() < 500;
  }

  slideRef(slide) {
    if (this.props.slide) {
      this._slide = slide;
    }
  }
  findFirstIndexByType(streamItems, type) {
    let index = -1;
    if (streamItems && streamItems.length > 0) {
      // Check if top ad exists
      const hasTopAd = streamItems.findIndex((item) => item.content_type === contentTypes.TOP_AD);
      // If there is no top ad in the list then return index of the first paragraph
      if (hasTopAd < 0) {
        index = streamItems.findIndex((item) => item.content_type === type);
      }
    }
    return index;
  }
  getStreamItemsByArticleType = (streamItems) => {
    if (!this.props.isMag) {
      //Repositioning top ad before first paragraph only for non-brMag articles/slides on mobile web
      this.addTopAdtoStreamItems(streamItems);
    } else {
      //If it is a brMag then Brn_atf_01 should be shown first
      const firstAd = this.findFirstIndexByType(streamItems, contentTypes.AD);
      if (firstAd >= 0) {
        streamItems[firstAd].content_type = contentTypes.TOP_AD;
      }
    }
    return streamItems;
  };
  getStreamItems = () => {
    if (this.props.pageType === ARTICLE && this.props.ui.isMobileDevice) {
      const streamItems = [...this.props.streamItems];
      return this.getStreamItemsByArticleType(streamItems);
    }
    return this.props.streamItems;
  };

  /**
   * Inserts an item before specific index
   * @param {Array} arr
   * @param {number} index
   * @param {any} newItem
   */
  insertItem = (arr, index, newItem) => arr.splice(index, 0, newItem);

  addTopAdtoStreamItems = (streamItems) => {
    const topAd = {
      content_type: contentTypes.TOP_AD,
    };
    if (streamItems) {
      const elements =
        this.props.slideshow && streamItems.length > 0 ? streamItems[0].elements : streamItems;
      const index = this.findFirstIndexByType(elements, contentTypes.PARAGRAPH);
      if (index >= 0) {
        if (this.props.slideshow) {
          this.insertItem(streamItems[0].elements, index, topAd);
          return;
        }
        this.insertItem(streamItems, index, topAd);
      }
    }
  };
  render() {
    if (this.props.streamItems.length === 0) {
      return false;
    }
    const classes = classnames({
      organism: true,
      contentStream: true,
      event: this.props.liveData,
      slideshow: this.props.slideshow,
      slide: this.props.slide,
      [this.props.className]: this.props.className,
    });
    const spinner =
      this.props.streamItems.length === 0 ? (
        <SVG type="icon" target="spinner" key={'icon'} />
      ) : (
        false
      );

    const isStandardArticle = this.props.pageType === ARTICLE && !this.props.slideshow;
    const updatedProps = {
      ...this.props,
      streamItems: this.getStreamItems(),
      onTrackImpression: this.onTrackImpression,
    };
    const streamList = buildContentStream(updatedProps);
    const children = isStandardArticle ? false : this.props.children;
    const whichRef = this.props.slide ? this.slideRef : this.props.streamRef;
    const hasSlides = this.props.slide && this.props.slideNumber > 0;
    const isIos = this.props.os === 'ios';
    const slideAd =
      (hasSlides && !isIos) || (hasSlides && this.props.slideNumber <= iosAdsLimit) ? (
        <LazyAdvert
          key={`ad-${this.props.page.article}-${this.props.slideNumber}`}
          id={`${this.props.page.article}-${this.props.slideNumber}`}
          country={this.props.visitorCountry}
          isVisitorOnMobile={this.props.isMobileDevice}
          position={`rect_atf_0${Math.min(this.props.slideNumber, 3)}`}
          page={this.props.page}
          tag={this.props.childTag}
        />
      ) : (
        false
      );
    let streamId;
    if (this.props.slide) {
      streamId = `slide${this.props.slideNumber}`;
    }

    return (
      <this.props.tag ref={whichRef} className={classes} id={streamId}>
        {slideAd}
        <ContentStreamTitle {...this.props} />
        {spinner}
        {children}
        <this.props.listTag key={'listTag'}>{streamList}</this.props.listTag>
      </this.props.tag>
    );
  }
}

export const defaultProps = {
  allowliveblog: false,
  childTag: 'div',
  featured() {
    return false;
  },
  header: false,
  info: {},
  isMag: false,
  isMobileDevice: false,
  listTag: 'ol',
  noimages: false,
  onArticleClick() {},
  os: '',
  pageType: null,
  showNewsletterSubsModule: false,
  slideshow: false,
  streamItems: [],
  tag: 'div',
  tickets: '',
  title: '',
  ui: {},
  updateVideosInViewport() {},
  user: {},
  visitorCountry: 'US', // user's country needs to be passed to any child Advert components
};

ContentStream.defaultProps = defaultProps;

export default ContentStream;
