import {House} from '../endpoints';
import {createFetchService} from './fetch_utils';

const fetchJSON = createFetchService('House');

const errorHandler = (err) => {
  return {
    error: err,
  };
};

export const fetchBets = (betTags, timezone) => {
  if (betTags) {
    return fetchJSON(House.bets(betTags, timezone)).catch(errorHandler);
  }
  return Promise.reject(new Error('No Bets ID passed to House API'));
};

export const fetchBetTrack = (betTrackId) => {
  if (betTrackId) {
    return fetchJSON(House.betTrack(betTrackId)).catch(errorHandler);
  }
  return Promise.reject(new Error('No bet track ID passed to House API'));
};

export default {
  fetchBets,
  fetchBetTrack,
};
