import {connect} from 'react-redux';
import classnames from 'classnames';
import {determineRel} from '../helpers/homePageHelpers';
import * as AnalyticsActions from '../../actions/analyticsActions';
import {getScreenType} from '../../constants/mParticle';

const ArticleTitle = (props) => {
  if (!props.title) {
    return false;
  }

  const classes = classnames({
    atom: true,
    articleTitle: true,
    lowerMargin: props.lowerMargin,
  });

  const rel = determineRel(props.target);

  const handleClick = () => {
    props.trackEvent({
      tag_manager_event: 'content_selected',
      urlHash: props.url,
      screen: getScreenType(props.ui?.pageType, props.ui?.pageSubType),
      contentType: 'article',
      springType: 'hyperlink',
      title: props.title,
    });
  };

  return (
    <a className={classes} href={props.url} target={props.target} rel={rel} onClick={handleClick}>
      <h3>{props.title}</h3>
    </a>
  );
};

ArticleTitle.defaultProps = {
  title: '',
  target: '_self',
  url: '#',
};

const mapStateToProps = (state) => {
  return {
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackEvent: (event) => dispatch(AnalyticsActions.trackEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTitle);
