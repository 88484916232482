import React from 'react';

export default function ExternalLinkIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H3.6V1.2H1.2V8.8H8.8V6.4H10V10H8.8L1.2 10H0V1.2V0ZM8.80001 4.8L9.99747 4.8L10 0.00255092H5.20255L5.20001 1.20255L7.89582 1.20064L2.60001 6.51693L3.4469 7.3671L8.80199 1.9913L8.80001 4.8Z"
        fill="#421CD6"
      />
    </svg>
  );
}
