import React from 'react';
import classnames from 'classnames';
import {max, sumBy} from 'lodash';
import {format} from 'date-fns';
import {Static} from '../../endpoints';

function allEqual(values) {
  return values.every((val) => val === values[0]);
}
const trophyIcon = `${Static.images()}/atoms/svg/icons/trophy.svg`;
const Option = ({answer, winner}) => {
  return (
    <div className="optionContainer">
      {answer.pick_percentage > 0 && (
        <div className="bar" style={{width: `${answer.pick_percentage}%`}} />
      )}
      <label>{answer.display_text}</label>
      <label className="voteResult">
        {(winner === 'all' || winner === answer.pick_percentage) && (
          <img alt="trophy-icon" className="trophyIcon" src={trophyIcon} />
        )}
        {`${answer.pick_percentage}%`}
      </label>
    </div>
  );
};
const Poll = (props) => {
  const {description, answers, isPollClosed, creationDate} = props;
  const classes = classnames({
    atom: true,
    poll: true,
  });

  const getWinner = () => {
    const percentages = answers.map((answer) => answer.pick_percentage);
    const all_equal = allEqual(percentages);
    if (all_equal) return 'all';
    return max(percentages);
  };

  const formattedDate = format(creationDate, 'MMMM D, YYYY');
  const winner = isPollClosed && getWinner();
  const voteCounts = sumBy(answers, (answer) => answer.pick_count);

  return (
    <div className={classes}>
      <header>
        <p>{description}</p>
        <div className="divider" />
        <label className="date">{formattedDate}</label>
      </header>
      {answers.map((answer) => (
        <Option answer={answer} winner={winner} key={answer.id} />
      ))}
      {voteCounts > 0 && (
        <label className="voteCount">{`${voteCounts} voted in the B/R app`}</label>
      )}
    </div>
  );
};

export default Poll;
