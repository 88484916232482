import React from 'react';
import classnames from 'classnames';
import Advert from './advert';
import debounce from 'lodash.debounce';
import * as AdsAPI from '../../apis/ads_api';
import {SIZES} from '../../constants/ads';

// TODO: CONS-3630 - change this to use domHelper isFullyInViewport
const isInViewport = (elem, buffer = 0) => {
  if (!elem || !global.document.contains(elem)) {
    // If the element has been removed from the DOM, it's not in the viewport.
    return false;
  }

  const {top, left, bottom} = elem.getBoundingClientRect();
  const {clientHeight, clientWidth} = document.documentElement;

  return (
    (top >= 0 &&
      left >= 0 &&
      top <= (global.innerHeight || clientHeight) + buffer &&
      left <= (global.innerWidth || clientWidth) + buffer) ||
    (left >= 0 &&
      bottom >= 0 &&
      bottom <= (global.innerHeight || clientHeight) + buffer &&
      left <= (global.innerWidth || clientWidth) + buffer)
  );
};

class LazyAdvert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
    this.getRef = this.getRef.bind(this);
    // debounce to ~3 frames at 60fps speeds (16ms)
    // this needs to be fast because scrolling is fast but
    // but scrolling really spams this event so needs limiting
    this.dbLazyLoad = debounce(this.lazyLoad.bind(this), 48);
  }

  componentDidMount() {
    this.adBufferSize = 1000;
    global.document.addEventListener('scroll', this.dbLazyLoad);
    this.dbLazyLoad();
  }

  shouldComponentUpdate() {
    return false;
  }

  lazyLoad() {
    if (this._elem && !this.state.isLoaded && isInViewport(this._elem, this.adBufferSize)) {
      this.setState({
        isLoaded: true,
      });
      global.document.removeEventListener('scroll', this.dbLazyLoad);
      global.brAdSystemPromise.then(() => {
        AdsAPI.loadSingleSlot([this._elem], global.utag_data);
      });
    }
  }

  getRef(elem) {
    this._elem = elem;
  }
  /**
   * Checks whether ad should be collapsed or not.
   */
  isCollapsed() {
    return this.props.page && this.props.page.tags && this.props.page.tags.includes('No_Ads');
  }
  properSize() {
    const sizes = this.props.size;
    /**
     * The size of 300x250 is not eligible
     * for desktop bnr slots and should be removed
     * if exists in the sizes and is not mobile
     */
    const shouldRemoveMediumSize =
      !this.props.isVisitorOnMobile &&
      this.props.position &&
      this.props.position.includes('bnr_atf_');

    if (shouldRemoveMediumSize) {
      return sizes.filter(
        (item) =>
          !(
            item.length > 1 &&
            item[0] === SIZES.MEDIUM_RECT.width &&
            item[1] === SIZES.MEDIUM_RECT.height
          )
      );
    }
    return sizes;
  }
  properResponsiveSize() {
    //rect_atf_03 slot should not use 2x2 ad in responsive size
    const isRectThirdSlot = this.props.position && this.props.position.includes('rect_atf_03');
    const responsiveSize = this.props.responsiveSize;
    const modifiedResponseSize = isRectThirdSlot ? 'articleThirdRect' : responsiveSize;
    return modifiedResponseSize;
  }
  render() {
    const classes = classnames({
      lazy: !this.state.isLoaded,
    });
    const collapsed = this.isCollapsed();
    return (
      <Advert
        country={this.props.country}
        tag={this.props.tag}
        isLazy={true}
        isVisitorOnMobile={this.props.isVisitorOnMobile}
        getRef={this.getRef}
        classes={classes}
        id={this.props.id}
        position={this.props.position}
        responsiveSize={this.properResponsiveSize()}
        size={this.properSize()}
        reload={1}
        collapsed={collapsed}
      />
    );
  }
}

LazyAdvert.defaultProps = {
  country: 'USA',
  position: 'rect_atf_01',
  responsiveSize: 'article',
  size: [
    [300, 600],
    [300, 250],
    [300, 2],
  ],
};

export default LazyAdvert;
