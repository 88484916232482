import React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import {Swipeable} from 'react-swipeable';

import {isFullyInViewport} from '../helpers/domHelpers';

const CAROUSEL_ITEM_MARGIN_RIGHT = 18;
// slide peak is only applicable for breakpoints above 1024: shootingGuard
const SLIDE_PEAK_VALUE = 0.5;

const CarouselPrevious = ({index, onClick}) => {
  const arrowClasses = classnames('atom icon svg rightArrow', {
    disabled: index === 0,
  });

  return (
    <div className="carouselPrevious" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" className={arrowClasses} viewBox="0 0 24 24">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

const CarouselNext = ({index, lastTrack, onClick, isLargeBreakpoint, tracksLength}) => {
  const arrowClasses = classnames('atom icon svg leftArrow', {
    disabled: index === lastTrack || (tracksLength === 2 && isLargeBreakpoint),
  });

  return (
    <div className="carouselNext" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" className={arrowClasses} viewBox="0 0 24 24">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </div>
  );
};

/**
 * A Carousel component that renders individal items passed as tracks.
 * This component allows previous and next navigation, but not currently loops.
 * This component can be resized and supports 5 breakpoints from mobile -> web.
 * @see betCarousel.scss for supported styled options ie. video, images, atoms
 */
class BetCarousel extends React.Component {
  static propTypes = {
    /**
     * React elements to used as tracks.
     */
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    /**
     * Additonal css classes
     */
    classes: PropTypes.string,
    /**
     * Header text for the carousel
     */
    header: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      carouselItemStyles: {},
      carouselItemTotalWidth: 0,
      carouselItemMargin: 0,
      currentIndex: 0,
      direction: '',
      isSliding: false,
      transformX: 0,
    };

    this.initCarouselPlaylist = this.initCarouselPlaylist.bind(this);
    this.onCarouselMove = this.onCarouselMove.bind(this);
    this.onCarouselNext = this.onCarouselNext.bind(this);
    this.onCarouselPrevious = this.onCarouselPrevious.bind(this);
    this.debouncedOnResize = debounce(this.onResize.bind(this), 300);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.carouselItemsRef = React.createRef();
  }

  componentDidMount() {
    this.initCarouselPlaylist();

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.debouncedOnResize);

    if (this.carouselItemsRef && this.carouselItemsRef.current) {
      // removes slide direction and any css animations once sliding is complete
      this.carouselItemsRef.current.addEventListener('transitionend', () => {
        this.setState({
          direction: '',
          isSliding: false,
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedOnResize);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  initCarouselPlaylist() {
    const {carouselWidth, carouselItemTotalWidth, carouselItemWidth} = this.carouselItemTotalWidths;
    // the active slide is determined by the hash or starts at 0
    const slideNumber = this.hashSlideNumber || 0;
    let transformX = 0;

    // slide position is based off margins initially for smallbreakpoints
    if (slideNumber && !this.isSmallBreakpoint) {
      transformX =
        (slideNumber - 1) * carouselItemTotalWidth + carouselItemWidth * SLIDE_PEAK_VALUE;
    }

    this.setState({
      ...this.marginStateProps,
      carouselWidth,
      carouselItemTotalWidth,
      carouselItemWidth,
      currentIndex: slideNumber,
      transformX,
    });
  }

  /***
   * Getter methods below let us compute values as they are accessed
   */

  get windowWidth() {
    if (document && document.body) {
      return document.body.clientWidth || document.documentElement.clientWidth;
    }

    return 0;
  }

  get carouselItemTotalWidths() {
    const {current} = this.carouselItemsRef;

    if (!current) {
      return {};
    }
    const {offsetWidth, clientWidth, width} = current.firstElementChild;
    const carouselWidth = current.offsetWidth || current.clientWidth || current.width;
    const carouselItemWidth = offsetWidth || clientWidth || width;
    const carouselItemTotalWidth = carouselItemWidth + CAROUSEL_ITEM_MARGIN_RIGHT;

    return {
      carouselWidth,
      carouselItemWidth,
      carouselItemTotalWidth,
    };
  }

  get carouselItemMargin() {
    const {carouselItemWidth} = this.carouselItemTotalWidths;
    const itemMargin = this.isSmallBreakpoint
      ? (this.windowWidth - carouselItemWidth) / 2
      : (this.windowWidth - carouselItemWidth) / 4;
    return isFinite(itemMargin) ? itemMargin : 0;
  }

  get isSmallBreakpoint() {
    return this.windowWidth < 970;
  }

  get isLargeBreakpoint() {
    return this.windowWidth > 1210;
  }

  get hashSlideNumber() {
    const hash = global.location && global.location.hash;
    const isSlideNumberIncluded = hash ? hash.includes('#slide') : false;
    let slideNumber = 0;

    if (isSlideNumberIncluded) {
      slideNumber = parseInt(hash.replace('#slide', ''));
    }

    return isFinite(slideNumber) ? slideNumber : null;
  }

  get marginStateProps() {
    const tracks = React.Children.toArray(this.props.children);
    if (this.isSmallBreakpoint || tracks.length === 1) {
      // gets the calculated margins once dom is mounted
      return {
        carouselItemMargin: this.carouselItemMargin,
        carouselItemStyles: {margin: `0 ${this.carouselItemMargin}px`},
      };
    }
    return {
      carouselItemStyles: {},
    };
  }

  onResize() {
    const {carouselWidth, carouselItemWidth, carouselItemTotalWidth} = this.carouselItemTotalWidths;

    this.setState({
      ...this.marginStateProps,
      carouselWidth,
      carouselItemMargin: this.carouselItemMargin,
      carouselItemTotalWidth,
      carouselItemWidth,
    });
  }

  onCarouselMove(index) {
    const {children} = this.props;
    let transformX;
    const {
      carouselWidth,
      carouselItemMargin,
      carouselItemWidth,
      carouselItemTotalWidth,
    } = this.state;
    if (index <= children.length - 1) {
      if (this.isSmallBreakpoint) {
        transformX = index * (carouselItemWidth + carouselItemMargin * 2);
      } else if (this.isLargeBreakpoint) {
        if (index === 0) {
          transformX = 0;
        } else if (carouselWidth < 2150) {
          transformX =
            carouselItemTotalWidth -
            (carouselWidth - carouselItemTotalWidth) / 2 +
            (index - 1) * carouselItemTotalWidth;
        } else {
          transformX = index * carouselItemTotalWidth;
        }
      } else if (index === 0) {
        transformX = 0;
      } else {
        transformX = carouselItemWidth * SLIDE_PEAK_VALUE + (index - 1) * carouselItemTotalWidth;
      }
      this.setState({
        currentIndex: index,
        isSliding: true,
        transformX,
      });
    }
  }

  onCarouselNext() {
    const {currentIndex} = this.state;
    this.onCarouselMove(currentIndex + 1);
  }

  onCarouselPrevious() {
    const {currentIndex} = this.state;
    if (currentIndex - 1 >= 0) {
      this.onCarouselMove(this.state.currentIndex - 1);
    }
  }

  carouselItems() {
    const {carouselItemStyles, currentIndex, isSliding, transformX} = this.state;
    const tracks = React.Children.toArray(this.props.children);
    const carouselItemsClasses = classnames('carouselItems', {
      isSliding,
      singleTrack: tracks.length === 1,
    });
    const styles = {
      transform: `translateX(${currentIndex > 0 ? '-' : ''}${transformX}px)`,
    };

    return (
      <div ref={this.carouselItemsRef} className={carouselItemsClasses} style={styles}>
        {tracks.map((track, index) => (
          <div
            key={index}
            className={classnames('carouselItem', {
              isActive: currentIndex === index,
            })}
            style={carouselItemStyles}
          >
            {track}
          </div>
        ))}
      </div>
    );
  }

  handleKeyDown(event) {
    const {children} = this.props;
    const {currentIndex} = this.state;

    if (isFullyInViewport(this._top10)) {
      if (currentIndex !== children.length - 1 && event.key === 'ArrowRight') {
        this.onCarouselNext();
      } else if (currentIndex !== 0 && event.key === 'ArrowLeft') {
        this.onCarouselPrevious();
      }
    }
  }

  render() {
    const {header, classes = '', children} = this.props;
    const {currentIndex} = this.state;
    const tracks = React.Children.toArray(children);
    const carouselClasses = classnames(
      {
        organism: !classes.includes('organism'),
      },
      'betCarousel',
      classes
    );

    return (
      <section
        className={carouselClasses}
        ref={(top10) => {
          this._top10 = top10;
        }}
      >
        {header ? <h2 className="carouselHeader">{header}</h2> : null}
        <Swipeable onSwipedLeft={this.onCarouselNext} onSwipedRight={this.onCarouselPrevious}>
          <div className="carouselContainer">
            <CarouselPrevious index={currentIndex} onClick={this.onCarouselPrevious} />
            {this.carouselItems()}
            <CarouselNext
              index={currentIndex}
              lastTrack={tracks.length - 1}
              onClick={this.onCarouselNext}
              isLargeBreakpoint={this.isLargeBreakpoint}
              tracksLength={tracks.length}
            />
          </div>
        </Swipeable>
      </section>
    );
  }
}

export default BetCarousel;
