import React from 'react';
import classnames from 'classnames';
import Timestamp from '../atoms/timestamp';

function TextPost(props) {
  const classNames = classnames({
    atom: true,
    textPost: true,
  });
  const timestamp = props.hasTimestamp ? props.timestamp : null;
  return (
    <props.tag className={classNames}>
      <Timestamp datetime={timestamp} />
      <article>
        <h3>{props.title}</h3>
        {props.content.split('\n\n').map((graf, index) => (
          <p key={`textpost-${props.id}-graf-${index}`}>{graf}</p>
        ))}
      </article>
    </props.tag>
  );
}

TextPost.defaultProps = {
  tag: 'div',
};

export default TextPost;
