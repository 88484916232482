import React from 'react';
import classnames from 'classnames';

import Timestamp from '../atoms/timestamp';
import Commentary from '../atoms/commentary';

const instagramLoad = () => {
  if (global.instgrm && global.instgrm.Embeds) {
    global.instgrm.Embeds.process();
  }
};

class InstagramEmbed extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    instagramLoad();
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const props = this.props;
    const isHttps = props.url.startsWith('https://');
    const classes = classnames({
      atom: true,
      error: !isHttps,
      instagramEmbed: true,
    });
    const timestamp = props.hasTimestamp ? props.timestamp : null;
    // abstracted from Instagram's Embed code
    return isHttps ? (
      <props.tag className={classes}>
        <Timestamp datetime={timestamp} />
        <Commentary {...props.commentary} />
        <blockquote
          className="instagram-media"
          data-instgrm-captioned="true"
          data-instgrm-version="7"
        >
          <div>
            <div>
              <div />
            </div>
            <p>
              <a href={props.url} target="_top" rel="noopener">
                A photo posted by {props.author_name} (@{props.username})
              </a>
            </p>
          </div>
        </blockquote>
      </props.tag>
    ) : (
      <div className={classes}>
        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
          Link to Media
        </a>
      </div>
    );
  }
}

InstagramEmbed.defaultProps = {
  addScript: () => {}, // stub
  tag: 'div',
  url: '',
};

export default InstagramEmbed;
