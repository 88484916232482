import React from 'react';
import Form from './form';
import PropTypes from 'prop-types';
import cn from 'classnames';

const InlineModule = ({isInFooter}) => {
  const classes = cn({
    inline: true,
    footer: isInFooter,
  });
  return <Form className={classes} isInFooter={isInFooter} />;
};

InlineModule.propTypes = {
  isInFooter: PropTypes.bool,
};

export default InlineModule;
