import classnames from 'classnames';

const buildList = (props) => {
  return props.list.map((listItem, index) => {
    return (
      <li key={`listItem-${props.id}-${index}`} dangerouslySetInnerHTML={{__html: listItem}} />
    );
  });
};

const BulletedList = (props) => {
  const classes = classnames({
    atom: true,
    bulletedList: true,
  });

  return <props.tag className={classes}>{buildList(props)}</props.tag>;
};

export default BulletedList;
