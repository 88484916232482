import React, {useEffect} from 'react';
import {useInView} from 'react-intersection-observer';
import classnames from 'classnames';
import {DateTime} from 'luxon';
import {format, isSameDay} from 'date-fns';

const outcomePercentages = (leftOutcome, rightOutcome) => {
  const leftPercentage = parseFloat(leftOutcome.bet_percentage);
  const rightPercentage = parseFloat(rightOutcome.bet_percentage);
  const containsValidPercentages =
    !isNaN(leftPercentage) && !isNaN(rightPercentage) && leftPercentage + rightPercentage === 1;
  return (
    <div className="percentage-container">
      {containsValidPercentages ? (
        <>
          <div>
            <label className="percentage-text left">{leftOutcome.bet_percentage_string}</label>
            <label className="percentage-text right">{rightOutcome.bet_percentage_string}</label>
          </div>
          <div className="bar-container">
            <div
              className="bar left"
              style={{backgroundColor: leftOutcome.color, width: `${leftPercentage * 100}%`}}
            />
            <div
              className="bar right"
              style={{backgroundColor: rightOutcome.color, width: `${rightPercentage * 100}%`}}
            />
          </div>
        </>
      ) : (
        <div className="no-percentage-message">Bet Percentages Coming Soon…</div>
      )}
    </div>
  );
};

const betOutcomes = (leftOutcome, rightOutcome, url, onOutcomeClick) => {
  const leftClasses = classnames('outcome', 'left', {
    noninteractive: !url,
    interactive: url,
  });
  const rightClasses = classnames('outcome', 'right', {
    noninteractive: !url,
    interactive: url,
  });
  return (
    <div className="bet-outcome-container">
      <a
        href={url}
        onClick={() => onOutcomeClick(leftOutcome.display_name, leftOutcome.line)}
        target="_blank"
        rel="noopener noreferrer"
        className={leftClasses}
        style={{backgroundColor: leftOutcome.color}}
      >
        <span className="outcome-name">{leftOutcome.display_name}</span>
        <span className="line">{leftOutcome.line}</span>
        {leftOutcome.did_win && <span className="checkmark" />}
      </a>
      <a
        href={url}
        onClick={() => onOutcomeClick(rightOutcome.display_name, rightOutcome.line)}
        target="_blank"
        rel="noopener noreferrer"
        className={rightClasses}
        style={{backgroundColor: rightOutcome.color}}
      >
        {rightOutcome.did_win && <span className="checkmark" />}
        <span className="outcome-name">{rightOutcome.display_name}</span>
        <span className="line">{rightOutcome.line}</span>
      </a>
    </div>
  );
};

const BetTrack = ({
  analytics = {},
  betName,
  headerText,
  leftOutcome,
  rightOutcome,
  url,
  startDate,
  lockedText,
  showSubText,
  timezone,
  trackEvent,
  partnerUrl,
  ctaText,
  iframe,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const formatStartDate = (dateInZuluTime, timezone) => {
    if (!dateInZuluTime) {
      return false;
    }
    const convertedDateInZuluTime = DateTime.fromISO(dateInZuluTime, {zone: timezone});
    const dateFormat = 'dddd[,] MMMM Do h:mma';
    const todayFormat = '[Today] h:mma';
    const isEventToday = isSameDay(convertedDateInZuluTime, new Date());
    const eventDateFormat = isEventToday ? todayFormat : dateFormat;
    return format(convertedDateInZuluTime, eventDateFormat);
  };
  const betPlatform = iframe ? 'amp' : 'web';

  useEffect(() => {
    if (inView && trackEvent) {
      trackEvent({
        screen: 'Article',
        link_type: 'betTrack',
        clickable: 'Y',
        partner_url: partnerUrl,
        cta_text: ctaText,
        bet_type: analytics.bet_type,
        bet_name: betName,
        league: analytics.league,
        partner: analytics.partner,
        bet_availability: 'Available',
        published_at: startDate,
        game_permalink: analytics.game_permalink,
        url_sha: url,
        bet_percentage_outcome_a: leftOutcome.bet_percentage,
        bet_percentage_outcome_b: rightOutcome.bet_percentage,
        tag_manager_event: 'bet_track_impression',
        bet_platform: betPlatform,
      });
    }
  }, [
    analytics.bet_type,
    analytics.game_permalink,
    analytics.league,
    analytics.partner,
    betName,
    ctaText,
    inView,
    leftOutcome.bet_percentage,
    partnerUrl,
    rightOutcome.bet_percentage,
    startDate,
    trackEvent,
    url,
    betPlatform,
  ]);

  const onOutcomeClick = (outcomeDisplayName, outcomeLine) => {
    trackEvent({
      screen: 'Article',
      link_type: 'betTrack',
      outcome: `${outcomeDisplayName} ${outcomeLine}`,
      partner_url: partnerUrl,
      cta_text: ctaText,
      bet_type: analytics.bet_type,
      bet_name: betName,
      league: analytics.league,
      partner: analytics.partner,
      published_at: startDate,
      game_permalink: analytics.game_permalink,
      url_sha: url,
      tag_manager_event: 'bet_track_link_selected',
      bet_platform: betPlatform,
    });
  };

  return (
    <div className="molecule bet-track" ref={ref}>
      <div className="bet-track_header">{headerText}</div>
      {outcomePercentages(leftOutcome, rightOutcome)}
      <div className="bet-track_container">
        {betOutcomes(leftOutcome, rightOutcome, url, onOutcomeClick)}
      </div>
      {showSubText && (
        <div className="offer-subtext">{lockedText || formatStartDate(startDate, timezone)}</div>
      )}
    </div>
  );
};

export default BetTrack;
